let lang = sessionStorage.getItem('language');
const BASE_URL = `https://api.aquatics.uz/${lang ? lang : 'ru'}`;

export const NEWS_URL = `${BASE_URL}/api/news`;        //Новости
export const YEARS_URL = `${BASE_URL}/api/years`;      //years
export const TEAMS_URL = `${BASE_URL}/api/teams`;      //Сборные команды
export const POOLS_URL = `${BASE_URL}/api/pools`;      //Бассейны
export const ABOUT_URL = `${BASE_URL}/api/about`;      //About
export const RULES_URL = `${BASE_URL}/api/rules`;      //Правила
export const STAFF_URL = `${BASE_URL}/api/staff`;      //Сотрудники федерации
export const ROUTERS_URL = `${BASE_URL}/api/menu`;         //Navbarlar uchun
export const SPORTS_URL = `${BASE_URL}/api/sport`;         //Sports
export const DOPING_URL = `${BASE_URL}/api/doping`;        //doping
export const VIDEOS_URL = `${BASE_URL}/api/videos`;        //video gallery
export const COACHES_URL = `${BASE_URL}/api/coaches`;      //тренеры
export const RECORDS_URL = `${BASE_URL}/api/records`;      //Рекорды
export const REGIONS_URL = `${BASE_URL}/api/regions`;      //Правила
export const REFEREES_URL = `${BASE_URL}/api/referees`;        //судьи
export const CHILDREN_URL = `${BASE_URL}/api/children`;        //children
export const ATHLETES_URL = `${BASE_URL}/api/athletes`;        //спортсмены
export const BRANCHES_URL = `${BASE_URL}/api/branches`;        //Филиалы федерации
export const PARTNERS_URL = `${BASE_URL}/api/partners`;        //Партнёры и спонсоры
export const CONTACTS_URL = `${BASE_URL}/api/contacts`;        //Contacts
export const LEADERSHIPS_URL = `${BASE_URL}/api/leaders`;        //Руководство федерация
export const DOCUMENTS_URL = `${BASE_URL}/api/documents`;          //Документы
export const GALLERIES_URL = `${BASE_URL}/api/galleries`;          //photo galleries
export const INTERVIEWS_URL = `${BASE_URL}/api/interviews`;        //interviews
export const TRANSLATIONS_URL = `${BASE_URL}/translations`;        //translate
export const TOURNAMENTS_URL = `${BASE_URL}/api/tournaments`;      //Соревнования
export const DEPARTMENTS_URL = `${BASE_URL}/api/departments`;      //Отделы федерации
export const REQUESTS_URL = `${BASE_URL}/api/section-requests`;        //Forms uchun
export const SOCIAL_LINKS_URL = `${BASE_URL}/api/social-links`;        //social links
export const ORGANIZATIONS_URL = `${BASE_URL}/api/organizations`;         //Организации (выдают сертификаты/документы)